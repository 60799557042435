import React, { useContext, useEffect } from 'react'
import AuthContext from 'contexts/AuthContext'
import Link from 'elements/Link/Link'
import Title from 'elements/Typography/Title'
import Subtle from 'elements/Typography/Subtle'
import Auth from '@aws-amplify/auth'
import {
  Text
} from '@chakra-ui/core'
import { LOGIN_ROUTE } from 'config/routes'

export default function LogoutPage () {
  const { setAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    async function signOut () {
      await Auth.signOut()
    }
    signOut()
    setAuthenticated(false)
  }, [setAuthenticated])

  return (
    <>
      <Title label="Logout">Signed out. <Subtle>Bye now.</Subtle></Title>
      <Text>
        You are signed out. Click <Link styled to={LOGIN_ROUTE}>here</Link> to
        sign back in.
      </Text>
    </>
  )
}
